// Regular expression taken from backend;
export const MARSHMALLOW_EMAIL_REGEX = RegExp(
  //dot-atom
  "(^[-!#$%&'*+/=?^`{}|~\\w]+(\\.[-!#$%&'*+/=?^`{}|~\\w]+)*@|" +
    //quoted sting
    '^"([\\001-\\010\\013\\014\\016-\\037!#-\\[\\]-\\177]|\\\\[\\001-\\011\\013\\014\\016-\\177])*"@)' +
    // domain
    "(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\\.)+" +
    "(?:[A-Z]{2,6}|[A-Z0-9-]{2,})$" +
    //literal form, ipv4 address (SMTP 4.1.3) +
    "|\\[(25[0-5]|2[0-4]\\d|[0-1]?\\d?\\d)" +
    "(\\.(25[0-5]|2[0-4]\\d|[0-1]?\\d?\\d)){3}\\]$",
  "i"
);

export function optionalStringConcat(
  separator: string,
  ...args: (string | null | undefined)[]
) {
  return args.filter((x) => x).join(separator);
}
