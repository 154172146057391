import { type Component, type PropType, markRaw, reactive } from "vue";

interface ComponentProps {
  type: string;
  maxlength: number;
  minlength: number;
  id: string;
  label: string;
  "data-testid": string;
}

interface ConfirmConfig {
  isOpen: boolean;
  message?: string;
  component?: PropType<Component> | null;
  componentProps?: ComponentProps | null;
  inputValue: string;
  confirmLabel?: string;
  cancelLabel?: string;
  confirm: () => void;
  cancel: () => void;
}

type ConfirmOptions = Pick<
  ConfirmConfig,
  "confirmLabel" | "cancelLabel" | "component" | "componentProps"
>;

const confirmConfig = reactive<ConfirmConfig>({
  isOpen: false,
  message: "",
  component: null,
  componentProps: null,
  inputValue: "",
  confirmLabel: "Save",
  cancelLabel: "Cancel",
  confirm: () => ({}),
  cancel: () => ({}),
});

export const useConfirm = () => {
  const confirm = (
    message: string,
    options: ConfirmOptions = {}
  ): Promise<boolean | string> => {
    const confirmPromise = new Promise<boolean | string>((resolve, reject) => {
      const defaults = {
        confirmLabel: "Save",
        cancelLabel: "Cancel",
        component: null,
        componentProps: null,
        inputValue: "",
        isOpen: true,
      };
      if (options.component) {
        options.component = markRaw(options.component);
      }
      Object.assign(confirmConfig, {
        ...defaults,
        ...options,
        message,
        confirm: resolve,
        cancel: reject,
      });
    });

    return confirmPromise.then(
      () => {
        confirmConfig.isOpen = false;
        if (confirmConfig.component) {
          return confirmConfig.inputValue;
        }
        return true;
      },
      () => {
        confirmConfig.isOpen = false;
        return false;
      }
    );
  };

  return {
    confirm,
    confirmConfig,
  };
};
