import { UserCreateInfo, UserInfo, UserUpdateInfo } from "@/models/user";
import { AxiosError, AxiosResponse } from "axios";
import { defineStore } from "pinia";
import AuthService from "@/services/AuthService";
import UserService from "@/services/UserService";
import jwt from "@/shared/JWT";
import { JwtPayload } from "@/shared/JWT";
import apiClient from "@/services";
import router from "@/router";
import { useConfirm } from "@/composables/useConfirm";
import { useInfo } from "@/composables/useInfo";

const { confirmConfig } = useConfirm();
const { infoConfig } = useInfo();

export const useUserStore = defineStore("users", {
  state: () => ({
    user: null as UserInfo | null,
    currentUserId: null as number | null,
    logoutId: 0,
  }),
  getters: {
    username(state) {
      return state.user?.username;
    },
    email(state) {
      return state.user?.email;
    },
    adminLevel(state) {
      return state.user?.adminLevel;
    },
    userId(state) {
      return state.currentUserId;
    },
    loggedIn(state) {
      return !!state.user;
    },
    orgId(state) {
      return state.user?.orgId;
    },
    org(state) {
      return state.user?.org;
    },
    mmo(state) {
      return state.user?.mmo;
    },
    currentUser(state) {
      return {
        firstName: state.user?.firstName,
        lastName: state.user?.lastName,
        email: state.user?.email,
      };
    },
  },
  actions: {
    setupAutoLogout(payload: JwtPayload) {
      const expiresIn = Math.floor(payload.exp - Date.now() / 1000);
      console.log(`Expires in ${expiresIn} seconds`);
      this.logoutId = window.setTimeout(() => {
        this.logout();
      }, expiresIn * 1000);
    },
    login(username: string, password: string) {
      return AuthService.login(username, password)
        .then((response: AxiosResponse) => {
          let { data } = response;
          if (Array.isArray(data)) {
            data = data[0];
          }
          const authToken = data.access_token;
          const jwtPayload = jwt.parseJWT(authToken);
          if (jwtPayload) {
            this.currentUserId = jwtPayload.user_id;
            localStorage.setItem("authToken", authToken);
            apiClient.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${authToken}`;
            this.setupAutoLogout(jwtPayload);
            return this.currentUserId;
          }
        })
        .catch((error: AxiosError) => {
          throw error;
        });
    },
    logout() {
      localStorage.removeItem("authToken");
      this.currentUserId = null;
      this.user = null;
      window.clearTimeout(this.logoutId);
      confirmConfig.isOpen = false;
      infoConfig.isOpen = false;
      router.push({ name: "login" });
    },
    loadUser(userId: number) {
      if (!userId) {
        console.warn("Cannot load user without userId");
        return;
      }
      return UserService.getUser(userId)
        .then((response: AxiosResponse) => {
          let { data } = response;
          if (Array.isArray(data)) {
            data = data[0];
          }
          return data;
        })
        .catch((error: AxiosError) => {
          throw error;
        });
    },
    updateUser(userId: number, userData: UserUpdateInfo) {
      return UserService.updateUser(userId, userData)
        .then((response: AxiosResponse) => {
          return response.data;
        })
        .catch((error: AxiosError) => {
          throw error;
        });
    },
    createUser(userData: UserCreateInfo) {
      return UserService.createUser(userData)
        .then((response: AxiosResponse) => {
          return response.data;
        })
        .catch((error: AxiosError) => {
          throw error;
        });
    },
    loadUsers(page: number, pageSize: number, filter: string, orgId: number) {
      return UserService.getList(page, pageSize, filter, orgId)
        .then((response: AxiosResponse) => {
          let { data } = response;
          if (Array.isArray(data)) {
            data = data[0];
          }
          return data;
        })
        .catch((error: AxiosError) => {
          throw error;
        });
    },
    updateUserStatus(userId: number, active: boolean): Promise<AxiosResponse> {
      return UserService.patchUser(userId, { active });
    },
    updateUserPassword(
      userId: number,
      password: string
    ): Promise<AxiosResponse> {
      return UserService.patchUser(userId, { password });
    },
  },
});
