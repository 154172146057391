import { UserCreateInfo, UserUpdateInfo } from "@/models/user";
import { AxiosResponse } from "axios";
import apiClient from ".";

export default {
  getUser(userId: number): Promise<AxiosResponse> {
    return apiClient.get(`/users/${userId}`);
  },
  updateUser(userId: number, userData: UserUpdateInfo): Promise<AxiosResponse> {
    return apiClient.put(`/users/${userId}`, userData);
  },
  createUser(userData: UserCreateInfo): Promise<AxiosResponse> {
    return apiClient.post(`/users`, userData);
  },
  patchUser(userId: number, payload: object): Promise<AxiosResponse> {
    return apiClient.patch(`/users/${userId}`, payload);
  },
  getList(
    offset: number,
    limit: number,
    filter: string,
    orgId: number | undefined
  ): Promise<AxiosResponse> {
    let path = `/users?_page=${offset}&_limit=${limit}`;
    if (filter) {
      path += `&_filter=${filter}`;
    }
    if (orgId) {
      path += `&orgId=${orgId}`;
    }
    return apiClient.get(path);
  },
};
