import { reactive } from "vue";

interface InfoConfig {
  isOpen: boolean;
  isError?: boolean;
  message?: string;
  ok: () => void;
}

type InfoOptions = Pick<InfoConfig, "isError">;

const infoConfig = reactive<InfoConfig>({
  isOpen: false,
  message: "",
  ok: () => ({}),
});

export const useInfo = () => {
  const info = (
    message: string,
    options: InfoOptions = {}
  ): Promise<boolean> => {
    const infoPromise = new Promise<boolean>((resolve) => {
      Object.assign(infoConfig, {
        isError: false,
        ...options,
        isOpen: true,
        message,
        ok: resolve,
      });
    });

    return infoPromise.then(() => {
      infoConfig.isOpen = false;
      return true;
    });
  };

  return {
    info,
    infoConfig,
  };
};
