import { defineStore } from "pinia";

export const useAppStore = defineStore("app", {
  state: () => ({
    version: process.env.VUE_APP_VERSION || "0.0.0",
    env: process.env.VUE_APP_ENV || "local",
  }),
  getters: {
    versionNumber(state) {
      return state.version;
    },
    envName(state) {
      return state.env;
    },
  },
});
