export interface JwtPayload {
  user_id: number;
  iat: number;
  exp: number;
}

export default {
  parseJWT(token: string): JwtPayload | null {
    try {
      const tokenPayload = token.split(".")[1];
      const obj = JSON.parse(window.atob(tokenPayload));
      return obj;
    } catch (e) {
      return null;
    }
  },
};
