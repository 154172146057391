import { AxiosResponse } from "axios";
import apiClient from ".";

export default {
  getOrg(orgId: number): Promise<AxiosResponse> {
    return apiClient.get(`/organisations/${orgId}`);
  },
  getList(
    offset: number | null,
    limit: number | null,
    filter: string | null
  ): Promise<AxiosResponse> {
    let path = "/organisations";
    if (offset || limit) {
      path += `?_page=${offset}&_limit=${limit}`;
    }
    if (filter) {
      path += `&_filter=${filter}`;
    }
    return apiClient.get(path);
  },
};
