import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MyAccountView from "../views/MyAccount.vue";
import LoginView from "../views/LoginForm.vue";
import UsersView from "../views/UserList.vue";
import EditUserView from "../views/EditUser.vue";
import NewUserView from "../views/NewUser.vue";
import MyOrganisationView from "../views/MyOrganisation.vue";
import HelpView from "../views/HelpPage.vue";
import jwt from "../shared/JWT";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "default",
    component: {
      template: "",
    },
  },
  {
    path: "/home",
    name: "home",
    component: MyAccountView,
    meta: { authRequired: true },
  },
  {
    path: "/users",
    name: "users",
    component: UsersView,
    meta: { authRequired: true },
  },
  {
    path: "/users/:userId(\\d+)/edit",
    name: "editUser",
    component: EditUserView,
    meta: { authRequired: true },
    props: true,
  },
  {
    path: "/users/new",
    name: "newUser",
    component: NewUserView,
    meta: { authRequired: true },
  },
  {
    path: "/org",
    name: "organisation",
    component: MyOrganisationView,
    meta: { authRequired: true },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/help",
    name: "help",
    component: HelpView,
    meta: { authRequired: false },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("authToken");
  const loggedIn = token && !!jwt.parseJWT(token);
  if (to.matched.some((record) => record.meta.authRequired) && !loggedIn) {
    next({
      name: "login",
    });
  } else {
    next();
  }
});

export default router;
