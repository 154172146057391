import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import axios from "axios";

import "./scss/custom.scss";

import { CIcon } from "@coreui/icons-vue";
import { cilExitToApp, cilUser, cilSearch } from "@coreui/icons";
import { useUserStore } from "./store/UserStore";
import { useOrganisationStore } from "./store/OrganisationStore";
import jwt from "./shared/JWT";
import apiClient from "./services";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import specific icons */
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";

/* add icons to the library */
library.add(faCircleQuestion, faUserSecret);

const icons = {
  cilExitToApp,
  cilUser,
  cilSearch,
};

const app = createApp(App).component("font-awesome-icon", FontAwesomeIcon);
app.use(createPinia()).use(router).mount("#app");
app.use(() => {
  const token = localStorage.getItem("authToken");
  const userStore = useUserStore();
  const orgStore = useOrganisationStore();
  if (token) {
    const jwtPayload = jwt.parseJWT(token);
    if (jwtPayload) {
      apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      userStore.setupAutoLogout(jwtPayload);
      userStore.currentUserId = jwtPayload.user_id;
      userStore.loadUser(userStore.currentUserId)?.then((user) => {
        userStore.user = user;
        if (user.adminLevel > 0 && user.orgId) {
          orgStore.currentOrgId = user.orgId;
          orgStore.loadOrg(user.orgId)?.then((organisation) => {
            orgStore.organisation = organisation;
          });
          orgStore.loadAllOrgs()?.then((organisations) => {
            orgStore.allOrgs = organisations.items;
          });
        }
        if (router.currentRoute.value.name == "default") {
          router.push({ name: user.adminLevel > 0 ? "users" : "home" });
        }
      });
    }
  } else {
    let prev = undefined;
    if (!["/login", "/"].includes(document.location.pathname)) {
      prev = document.location.pathname;
      console.log("prev");
      console.log(prev);
    }
    if (prev != "/help")
      router.push({
        name: "login",
        query: { prev },
      });
  }
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        router.push({ name: "home" }); // todo: non authorised page
      }
      return Promise.reject(error);
    }
  );
});
app.provide("icons", icons);
app.component("CIcon", CIcon);
