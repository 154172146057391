import { BasicOrgInfo, OrgInfo } from "@/models/org";
import { AxiosError, AxiosResponse } from "axios";
import { defineStore } from "pinia";
import OrgService from "@/services/OrgService";

export const useOrganisationStore = defineStore("organisations", {
  state: () => ({
    organisation: null as OrgInfo | null,
    currentOrgId: null as number | null,
    allOrgs: null as BasicOrgInfo[] | null,
  }),
  getters: {
    currentOrg(state) {
      return {
        name: state.organisation?.name,
        active: state.organisation?.active,
        licenceNumber: state.organisation?.licenceNumber,
        productAccess: state.organisation?.productAccess,
        sector: state.organisation?.sector,
        mmo: state.organisation?.mmo,
        relatedOrgs:
          state.organisation && state.organisation.mmo && state.allOrgs
            ? state.allOrgs
                .filter(function (org) {
                  return org.id != state.currentOrgId && org.active;
                })
                .sort((a, b) =>
                  a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                )
            : [],
        availableOrgs: [
          ...(state.organisation
            ? [
                {
                  id: String(state.organisation.id),
                  name: state.organisation.name,
                },
              ]
            : []),
          ...(state.organisation && state.organisation.mmo && state.allOrgs
            ? state.allOrgs
                .filter(function (org) {
                  return org.id != state.currentOrgId && org.active;
                })
                .map(function (org) {
                  return { id: String(org.id), name: org.name };
                })
            : []),
        ],
      };
    },
    orgId(state) {
      return state.currentOrgId;
    },
  },
  actions: {
    loadOrg(orgId: number) {
      if (!orgId) {
        console.warn("Cannot load organisation without orgId");
        return;
      }
      return OrgService.getOrg(orgId)
        .then((response: AxiosResponse) => {
          let { data } = response;
          if (Array.isArray(data)) {
            data = data[0];
          }
          return data;
        })
        .catch((error: AxiosError) => {
          throw error;
        });
    },
    loadOrgs(
      page: number | null,
      pageSize: number | null,
      filter: string | null
    ) {
      return OrgService.getList(page, pageSize, filter)
        .then((response: AxiosResponse) => {
          let { data } = response;
          if (Array.isArray(data)) {
            data = data[0];
          }
          return data;
        })
        .catch((error: AxiosError) => {
          throw error;
        });
    },
    loadAllOrgs() {
      return this.loadOrgs(null, null, null);
    },
  },
});
