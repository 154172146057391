import axios, { AxiosResponse } from "axios";

const authClient = axios.create({
  baseURL: process.env.VUE_APP_AUTH_URL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
  },
});

export default {
  login(username: string, password: string): Promise<AxiosResponse> {
    return authClient.post("/auth/login", undefined, {
      headers: {
        Authorization: `Basic ${btoa(username + ":" + password)}`,
      },
    });
  },
};
