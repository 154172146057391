
import { Vue, Options } from "vue-class-component";

@Options({
  props: {
    version: String,
    env: String,
  },
})
export default class Ribbon extends Vue {
  version!: string;
  env!: string;

  get tag(): string {
    return `${this.env} ${this.version}`;
  }

  get enabled(): boolean {
    return !!this.env && !!this.version && this.env !== "prod";
  }
}
